import ErrorPage from '../components/ErrorPage';
import { useOwner } from '@watershed/shared-frontend/hooks/useOwner';
import { Teams } from '@watershed/constants/teams';

export default function Admin404Page() {
  useOwner(Teams.Infrastructure);
  return (
    <ErrorPage
      error={{
        name: 'Not found',
        message: 'Page not found',
        code: 'NOT_FOUND',
      }}
    />
  );
}
